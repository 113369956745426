<template>
  <v-chip
    class="ma-2"
    color="success"
    variant="outlined"
    prepend-icon="mdi-account-circle"
    link
    @click="signOut"
    size="large"
  >
    {{ username }}
    <v-tooltip activator="parent" location="start">Sign out</v-tooltip>
  </v-chip>
</template>

<script>
import { Auth } from "aws-amplify";
import awsmobile from "@/aws-exports";

Auth.configure(awsmobile);

export default {
  name: "SignOutButton",
  props: {
    username: String,
  },
  methods: {
    async signOut() {
      try {
        await Auth.signOut();
        this.$emit("user-logged-out");
      } catch (error) {
        console.error("Error signing out:", error);
      }
    },
  },
};
</script>
