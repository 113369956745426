<!-- GameForm.vue -->
<template>
  <div>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-card>
            <v-img
              class="align-end text-white"
              height="400"
              :src="coverImage"
              cover
            >
            </v-img>
            <v-card-text>
              <v-form @submit.prevent="submitForm" ref="gameForm">
                <v-stepper
                  v-model="currentStep"
                  :next-text="nextButtonText"
                  :editable="true"
                >
                  <slot></slot>
                  <v-stepper-actions
                    @click:prev="previous"
                    @click:next="next"
                    :disabled="disabled"
                  >
                  </v-stepper-actions>
                </v-stepper>

                <v-dialog
                  v-model="isLoading"
                  :scrim="false"
                  persistent
                  width="auto"
                >
                  <v-card color="primary">
                    <v-card-text>
                      Uploading your customization...
                      <v-progress-linear
                        indeterminate
                        color="white"
                        class="mb-0"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-dialog>
                <v-dialog
                  v-model="isDone"
                  :scrim="false"
                  width="auto"
                  persistent
                >
                  <v-card>
                    <v-card-text>
                      Your customization was successfully uploaded!
                    </v-card-text>
                    <v-card-actions class="text-end">
                      <v-spacer></v-spacer>
                      <router-link
                        :to="{
                          name: game_page_map[gameId],
                          query: {
                            shareablecode: this.responseBody.shareable_code,
                          },
                        }"
                      >
                        <v-btn color="primary" outlined> Play now </v-btn>
                      </router-link>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import { Auth } from "aws-amplify";

async function getJwtToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error("Error getting JWT token:", error);
    return null;
  }
}

export default {
  props: {
    gameId: {
      type: String,
      required: true,
    },
    formData: {
      type: Object,
      required: true,
    },
    coverImage: {
      type: String,
      required: true,
    },
    files: {
      type: Array,
      default: () => [],
    },
    numTabs: {
      type: Number,
      default: 4,
    },
  },
  data() {
    return {
      form: this.formData,
      isLoading: false,
      isDone: false,
      responseBody: null,
      dialog1: false,
      dialog2: false,
      game_page_map: {
        "game-0001": "CustomKeeperGame",
        "game-0002": "FlappyFriendsGame",
        "game-0003": "SelfSliderGame",
      },
      currentStep: 0,
      nextButtonText: "Next",
    };
  },
  inject: ["user"],
  methods: {
    async submitForm() {
      const { valid } = await this.$refs.gameForm.validate();
      if (!valid) {
        return;
      }
      this.isLoading = true;
      const customizationData = {};
      this.$emit("formatData", customizationData);

      const token = await getJwtToken();
      try {
        const fileUploadPromises = this.files.map((fileKey) => {
          if (this.form[fileKey] instanceof Blob) {
            return this.uploadFile(this.form[fileKey]).then((response) => ({
              key: fileKey,
              response,
            }));
          }
          return null;
        });

        const fileUploadResponses = await Promise.all(fileUploadPromises);

        fileUploadResponses.forEach((response) => {
          if (response) {
            customizationData.custom_images[response.key] = response.response;
          }
        });

        const response = await axios.post(
          process.env.VUE_APP_API_URL + "customizations/",
          {
            game_id: this.gameId,
            customization_data: customizationData,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        console.log(response);
        this.responseBody = response.data;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
        this.isDone = true;
      }
    },
    formatData() {
      return this.$emit("formatData", this.form);
    },
    fileToBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event) => {
          const base64DataUrl = event.target.result;
          // Remove the data scheme from the result
          const base64String = base64DataUrl.split(",")[1];
          resolve(base64String);
        };
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    },
    async uploadFile(file) {
      var filename = "Cropped image";
      if ("name" in file) {
        filename = file.name;
      }
      const base64File = await this.fileToBase64(file);
      const token = await getJwtToken();
      const response = await axios.post(
        process.env.VUE_APP_API_URL + "files",
        JSON.stringify({ fileContent: base64File }),
        {
          headers: {
            "Content-Type": "application/json",
            "x-file-name": filename,
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const fileId = response.data.file_id;
        console.log(fileId);
        return fileId;
      } else {
        throw new Error(`Failed to upload file: ${filename}`);
      }
    },
    next() {
      if (this.currentStep < this.numTabs - 1) {
        this.currentStep++;
      } else {
        this.submitForm();
      }
    },
    previous() {
      if (this.currentStep >= 1) {
        this.currentStep--;
      }
    },
  },
  computed: {
    disabled() {
      if (this.currentStep === 0) {
        return "prev";
      } else if (this.isLoading || this.isDone) {
        return "next";
      } else {
        return false;
      }
    },
  },
  watch: {
    currentStep(newValue) {
      if (newValue == this.numTabs - 1) {
        this.nextButtonText = "Submit";
      } else {
        this.nextButtonText = "Next";
      }
    },
  },
};
</script>

<style>
.v-card {
  background-color: rgba(255, 255, 255, 0.9);
}
</style>
