<template>
  <div>
    <v-app-bar app :elevation="4">
      <v-app-bar-nav-icon
        v-if="this.$vuetify.display.mobile"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>

      <v-img
        :src="require('@/assets/gg_logo_text.png')"
        height="90%"
        alt="logo"
      ></v-img>
      <v-spacer></v-spacer>

      <!-- Desktop Navigation -->
      <div v-if="!this.$vuetify.display.mobile">
        <v-btn to="/" title="Home" prepend-icon="mdi-home"> Home </v-btn>
        <v-btn to="/howto" title="How to" prepend-icon="mdi-school">
          How to
        </v-btn>
        <v-btn to="/catalog" title="Games" prepend-icon="mdi-gamepad">
          Catalog
        </v-btn>
        <v-btn v-if="user" prepend-icon="mdi-puzzle-edit">
          Customize
          <v-menu open-on-hover activator="parent">
            <v-list density="compact">
              <v-list-item
                v-for="([title, location], i) in games"
                :key="i"
                :title="title"
                :to="location"
                link
              >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn>

        <v-btn v-if="user" prepend-icon="mdi-account-circle">
          User
          <v-menu open-on-hover activator="parent">
            <v-list density="compact">
              <v-list-item
                v-for="([title, location], i) in user_list"
                :key="i"
                :title="title"
                :to="location"
                link
              >
              </v-list-item>
            </v-list>
          </v-menu>
        </v-btn>
      </div>

      <v-spacer v-if="!this.$vuetify.display.mobile"></v-spacer>

      <div v-if="user">
        <sign-out-button
          :username="getUsername()"
          @user-logged-out="updateUserStatus"
        ></sign-out-button>
      </div>
      <div v-else-if="isLoading">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <div v-else>
        <sign-in-button @user-logged-in="updateUserStatus"></sign-in-button>
      </div>
    </v-app-bar>
  </div>

  <v-navigation-drawer v-if="$vuetify.display.mobile" v-model="drawer" app>
    <v-list>
      <v-list-item
        to="/"
        prepend-icon="mdi-home"
        title="Home"
        nav
      ></v-list-item>
      <v-list-item to="/howto" prepend-icon="mdi-school" title="How to" nav>
      </v-list-item>
      <v-list-item to="/catalog" prepend-icon="mdi-gamepad" title="Catalog" nav>
      </v-list-item>
      <v-list-group v-if="user" title="Customize">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-puzzle-edit"
            title="Customize"
            nav
          ></v-list-item>
        </template>

        <v-list-item
          v-for="([title, location], i) in games"
          :key="i"
          :title="title"
          :to="location"
          nav
        >
        </v-list-item>
      </v-list-group>
      <v-list-group title="User" v-if="user">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-account-circle"
            title="User"
            nav
          ></v-list-item>
        </template>
        <v-list-item
          v-for="([title, location], i) in user_list"
          :key="i"
          :title="title"
          :to="location"
          nav
        ></v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import SignInButton from "./SignInButton.vue";
import SignOutButton from "./SignOutButton.vue";

export default {
  components: {
    SignInButton,
    SignOutButton,
  },
  data() {
    return {
      drawer: false,
      games: [
        ["CustomKeeper", "/games/custom-keeper"],
        ["FlappyFriends", "/games/flappy-friends"],
        ["SelfSlider", "/games/self-slider"],
      ],
      user_list: [
        // ["My account", "/"],
        ["My games", "/user/games"],
      ],
    };
  },
  props: {
    user: Object,
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["userStatusChanged"],
  methods: {
    updateUserStatus(status) {
      this.$emit("user-status-changed", status);
    },
    getUsername() {
      if ("preferred_username" in this.user.attributes) {
        return this.user.attributes.preferred_username;
      } else {
        return this.user.username;
      }
    },
  },
};
</script>
