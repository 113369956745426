<template>
  <v-footer class="my-footer bg-teal text-center d-flex flex-column">
    <v-btn
      variant="plain"
      prepend-icon="mdi-email"
      href="mailto: info@getgamegifts.com"
      >info@getgamegifts.com</v-btn
    >
  </v-footer>
</template>

<style>
.my-footer {
  flex: 0 0 auto;
}
</style>
