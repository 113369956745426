<template>
  <v-timeline side="end">
    <v-timeline-item
      icon="mdi-account-plus"
      dot-color="primary"
      size="large"
      fill-dot
    >
      <v-card elevation="2" class="cards">
        <v-card-title class="text-wrap"> Create an account </v-card-title>
        <v-card-text>
          If it's your first time here, you should sign up. It's free and we
          don't keep any data other than your username, password and email
          address!
        </v-card-text>

        <v-chip v-if="user()" color="secondary" size="x-large" class="mb-2">
          Hi there, {{ getUsername() }}!
        </v-chip>
        <v-btn
          v-else
          large
          color="secondary"
          variant="outlined"
          :onclick="signIn"
        >
          Sign up/Sign in
        </v-btn>
      </v-card>
    </v-timeline-item>
    <v-timeline-item
      icon="mdi-gamepad-variant-outline"
      dot-color="primary"
      size="large"
      fill-dot
    >
      <v-card elevation="2" class="cards" style="padding-bottom: 10px">
        <v-card-title class="text-wrap">
          Choose a game from the library
        </v-card-title>
        <v-card-text>
          We've got a selection of games to choose from. Would you like to see
          your kid on the moon, or your friends in an airplane? It's your
          choice!
        </v-card-text>

        <v-btn large color="secondary" variant="outlined" to="/catalog">
          Games Catalog
        </v-btn>
      </v-card>
    </v-timeline-item>
    <v-timeline-item
      icon="mdi-puzzle-edit-outline"
      dot-color="primary"
      size="large"
      fill-dot
    >
      <v-card elevation="2" class="cards">
        <v-card-title class="text-wrap"> Customize your game </v-card-title>
        <v-card-text>
          Customize your game the way you want it. Upload a picture of your
          games character, add your own welcome or game over message, and set
          the difficulty level.
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item
      icon="mdi-link-variant"
      dot-color="primary"
      size="large"
      fill-dot
    >
      <v-card elevation="2" class="cards">
        <v-card-title class="text-wrap"> Share the link </v-card-title>
        <v-card-text>
          Sharing your customized game is super easy! It's as simple as sending
          someone the link to your game. Anyone with the link can see and play
          your game, free of charge!
        </v-card-text>
      </v-card>
    </v-timeline-item>
    <v-timeline-item icon="mdi-play" dot-color="primary" size="large" fill-dot>
      <v-card elevation="2" class="cards">
        <v-card-title class="text-wrap">
          Download the app, or play in your browser
        </v-card-title>
        <v-card-text>
          There's many ways of playing your game. You can play it directly in
          the browser, or you can download one of our mobile apps.
        </v-card-text>
      </v-card>
    </v-timeline-item>
  </v-timeline>
</template>

<script>
import { Auth } from "aws-amplify";
import awsmobile from "@/aws-exports";

Auth.configure(awsmobile);

export default {
  inject: ["user"],
  methods: {
    async signIn() {
      try {
        await Auth.federatedSignIn();
        this.$emit("user-logged-in", true);
      } catch (error) {
        console.error("Error signing in:", error);
        this.$emit("user-logged-in", false);
      }
    },
    getUsername() {
      if ("preferred_username" in this.user().attributes) {
        return this.user().attributes.preferred_username;
      } else {
        return this.user().username;
      }
    },
  },
};
</script>

<style scoped>
button {
  margin: 10px;
}

.cards {
  max-width: 500px;
  width: 100%;
}
</style>
