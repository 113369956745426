<template>
  <v-chip
    class="ma-2"
    color="red"
    variant="outlined"
    prepend-icon="mdi-account-circle"
    link
    size="large"
    @click="signIn"
  >
    Not logged in
    <v-tooltip activator="parent" location="start">Sign in</v-tooltip>
  </v-chip>
</template>

<script>
import { Auth } from "aws-amplify";
import awsmobile from "@/aws-exports";

Auth.configure(awsmobile);

export default {
  name: "SignInButton",
  methods: {
    async signIn() {
      try {
        await Auth.federatedSignIn();
        this.$emit("user-logged-in", true);
      } catch (error) {
        console.error("Error signing in:", error);
        this.$emit("user-logged-in", false);
      }
    },
  },
};
</script>
