<template>
  <v-card>
    <v-alert
      v-if="!imageSrc"
      type="info"
      text="You've not uploaded an image yet"
      density="compact"
    ></v-alert>
    <v-alert
      v-if="!!imageSrc & !isValid"
      type="error"
      text="The image you've uploaded is not a valid image."
      density="compact"
    ></v-alert>
    <v-alert
      v-if="!!imageSrc & !isTransparent & isValid"
      type="warning"
      density="compact"
    >
      It looks like the image that you've uploaded is not transparant. For the
      nicest results in your game, make sure that image you select has a
      transparant background. You can use a tool like
      <a href="https://removal.ai" target="_blank">this</a> to do so.
    </v-alert>
    <v-alert
      v-if="!!imageSrc & isTransparent & isValid"
      type="success"
      text="The image you've selected seems to pass our checks. If you like how it looks, you can continue the process!"
      density="compact"
    ></v-alert>
    <v-img :src="require('@/assets/ck-preview-background.png')" height="438">
      <v-img
        v-if="imageSrc"
        :src="imageSrc"
        style="margin-top: 50px; margin-left: 3px"
        height="100"
      ></v-img>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    imageSrc: {
      type: [String, null],
      required: true,
    },
    fileBlob: {
      type: [Blob, null],
      required: true,
    },
  },
  data: function () {
    return {
      isTransparent: false,
      isValid: false,
    };
  },
  methods: {
    checkCornerTransparency() {
      if (this.fileBlob == null) {
        return;
      }
      this.isValid = true;
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);

        const corners = [
          { x: 0, y: 0 }, // Top-left
          { x: img.width - 1, y: 0 }, // Top-right
          { x: 0, y: img.height - 1 }, // Bottom-left
          { x: img.width - 1, y: img.height - 1 }, // Bottom-right
        ];

        for (const corner of corners) {
          const pixelData = ctx.getImageData(corner.x, corner.y, 1, 1).data;
          if (pixelData[3] < 255) {
            this.isTransparent = true;
            URL.revokeObjectURL(img.src);
            return;
          }
        }

        this.isTransparent = false;
        URL.revokeObjectURL(img.src);
      };

      img.onerror = () => {
        this.isValid = false;
        URL.revokeObjectURL(img.src);
      };

      img.src = URL.createObjectURL(this.fileBlob);
    },
  },
  watch: {
    imageSrc: "checkCornerTransparency",
  },
};
</script>
