<template>
  <v-container>
    <v-row justify-center>
      <v-col cols="12">
        <v-alert type="info" density="compact" closable
          >Loading can be slow the first time. MacOS might have some
          issues.</v-alert
        >
        <v-card height="95%" :max-width="maxWidth" :max-height="maxHeight">
          <iframe
            id="game-iframe"
            :class="{ fullscreen: isFullscreen && isIOSDevice }"
            :src="`${publicPath}${url}${getQueryParams}`"
            width="100%"
            height="100%"
            frameborder="0"
            allow="fullscreen"
          ></iframe>
          <v-overlay
            v-if="this.$vuetify.display.mobile"
            :model-value="!isFullscreen"
            class="align-center justify-center"
            contained
            persistent
          >
            <v-btn
              @click="toggleFullscreen"
              v-if="!isFullscreen"
              variant="flat"
              append-icon="mdi-fullscreen"
              >Fullscreen
            </v-btn>
          </v-overlay>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true,
    },
    maxWidth: {
      type: Number,
      required: true,
    },
    maxHeight: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      isFullscreen: false,
    };
  },
  computed: {
    isIOSDevice() {
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    },
    getQueryParams() {
      if (this.$route.query.shareablecode === undefined) {
        return "";
      } else {
        return `?shareablecode=${this.$route.query.shareablecode}`;
      }
    },
  },
  mounted() {
    document.addEventListener("fullscreenchange", this.handleFullscreenChange);
  },
  beforeUnmount() {
    document.removeEventListener(
      "fullscreenchange",
      this.handleFullscreenChange
    );
  },

  methods: {
    toggleFullscreen() {
      if (this.isIOSDevice) {
        this.isFullscreen = !this.isFullscreen;
      } else {
        let iframe = document.getElementById("game-iframe");

        if (!document.fullscreenElement) {
          if (iframe.requestFullscreen) {
            iframe.requestFullscreen();
          } else if (iframe.msRequestFullscreen) {
            iframe.msRequestFullscreen();
          } else if (iframe.mozRequestFullScreen) {
            iframe.mozRequestFullScreen();
          } else if (iframe.webkitRequestFullscreen) {
            iframe.webkitRequestFullscreen();
          }
        } else {
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
          }
        }
      }
    },
    handleFullscreenChange() {
      this.isFullscreen = !!document.fullscreenElement;
    },
  },
};
</script>

<style>
.v-container {
  height: 100%;
  padding: 0;
  margin-top: 16px;
}

.v-row,
.v-col {
  height: 100%;
  margin: 0%;
  padding: 0%;
}

.fullscreen {
  position: fixed; /* Or absolute, depending on your layout */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  border: none;
  z-index: 1000; /* Ensure it's above other elements */
}
</style>
