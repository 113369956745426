<template>
  <v-card
    class="d-flex flex-column justify-center"
    width="100%"
    style="padding-bottom: 6px"
  >
    <v-img :src="image" height="200px" cover></v-img>

    <v-card-title class="text-start">
      <v-row>
        <v-col>
          {{ title }}
        </v-col>
        <v-col v-for="platform in platforms" :key="platform" cols="auto">
          <v-tooltip :text="platform" bottom>
            <template v-slot:activator="{ props }">
              <v-icon
                :icon="platform_icons[platform]"
                v-bind="props"
                size="28"
              ></v-icon>
            </template>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-subtitle class="text-start"> {{ subtitle }} </v-card-subtitle>
    <v-card-text class="text-start"> {{ description }} </v-card-text>
    <v-spacer></v-spacer>
    <v-row class="justify-center"> </v-row>
    <v-card-actions class="justify-center">
      <v-btn color="primary" variant="outlined" :to="demo_link" class="mr-4">
        Demo
      </v-btn>
      <v-btn color="secondary" variant="outlined" :to="customize_link">
        Customize
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "GameCard",
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    demo_link: {
      type: String,
      required: true,
    },
    customize_link: {
      type: String,
      required: true,
    },
    platforms: {
      type: Array,
      required: true,
    },
  },
  data: function () {
    return {
      platform_icons: {
        android: "mdi-android",
        ios: "mdi-apple",
        windows: "mdi-microsoft-windows",
        mac: "mdi-apple",
        linux: "mdi-linux",
        web: "mdi-web",
      },
    };
  },
};
</script>

<style scoped>
.full-width {
  width: 100%;
}
</style>
