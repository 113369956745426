<template>
  <div class="home-page">
    <v-row justify="center">
      <v-col
        v-for="game in games"
        :key="game.id"
        cols="12"
        md="6"
        lg="4"
        max-width="600px"
        class="d-flex align-center justify-center"
      >
        <game-card
          :image="game.image"
          :title="game.title"
          :subtitle="game.subtitle"
          :description="game.description"
          :demo_link="game.demo_link"
          :customize_link="game.customize_link"
          :platforms="['web', 'windows']"
          height="100%"
        ></game-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import GameCard from "../components/GameCard.vue";

export default {
  name: "HomePage",
  data() {
    return {
      games: [
        {
          id: 1,
          image: require("@/assets/ck_cover.png"),
          title: "CustomKeeper",
          subtitle: "Stopping penalties on the moon",
          description:
            "Have you always wanted to be a goalie, but you can't jump high enough? Have you always wanted to go to the moon, but you don't have a rocket to bring you there? Then look no further, because CustomKeeper lets you fulfill those dreams!",
          demo_link: "/games/custom-keeper/play",
          customize_link: "/games/custom-keeper",
        },
        {
          id: 2,
          image: require("@/assets/ff_cover.png"),
          title: "FlappyFriends",
          subtitle: "Help your friends stay in flight",
          description:
            "Your friends are flapping around in a plane, but have no idea how to fly one! Keep them in flight for as long as possible, while collection as many bonusses as possible.",
          demo_link: "/games/flappy-friends/play",
          customize_link: "/games/flappy-friends",
        },
        {
          id: 3,
          image: require("@/assets/ss_cover.png"),
          title: "SelfSlider",
          subtitle: "Combine the blocks and reach the highest score possible",
          description:
            "An addictive puzzle game based on the game '2048'. Slide blocks to combine ones with the same value. Try to get to the highest value possible!",
          demo_link: "/games/self-slider/play",
          customize_link: "/games/self-slider",
        },
      ],
    };
  },
  components: {
    GameCard,
  },
};
</script>

<style scoped>
.home-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

button {
  margin: 10px;
}
</style>
