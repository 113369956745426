const awsmobile = {
  region: "us-east-1",
  userPoolId: "us-east-1_CGJxv8Zxz",
  userPoolWebClientId: "64hnjemh462bl08o3tta09ngg5",
  oauth: {
    domain: "cg.auth.us-east-1.amazoncognito.com",
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: process.env.VUE_APP_SIGNIN_URL || "http://localhost:8080/",
    redirectSignOut:
      process.env.VUE_APP_SIGNOUT_URL || "http://localhost:8080/",
    responseType: "code",
  },
};

export default awsmobile;
