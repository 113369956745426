<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <h1>My games</h1>
        <div v-if="error">
          <v-alert
            type="error"
            title="Error"
            text="Error encountered while fetching your games"
          ></v-alert>
        </div>
        <div v-else-if="getCustomizations === null">
          <v-progress-circular
            :size="70"
            :width="7"
            color="purple"
            indeterminate
          ></v-progress-circular>
        </div>
        <div v-else-if="getCustomizations.length === 0">
          <v-alert type="info">No games found</v-alert>
        </div>
        <div v-else class="expanders-container">
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(customization, index) in getCustomizations"
              :key="index"
            >
              <v-expansion-panel-title>
                {{
                  title_map[customization.game_id] +
                  ": " +
                  customization.metadata.gift_name
                }}
                <v-spacer></v-spacer>
                <router-link
                  :to="{
                    name: 'GameInfo',
                    query: {
                      'game-id': customization.game_id,
                      shareablecode: customization.shareable_code,
                    },
                  }"
                >
                  <v-btn
                    small
                    color="primary"
                    append-icon="mdi-information"
                    outlined
                  >
                    Instructions
                  </v-btn>
                </router-link>
                <router-link
                  :to="{
                    name: game_page_map[customization.game_id],
                    query: { shareablecode: customization.shareable_code },
                  }"
                >
                  <v-btn
                    small
                    color="secondary"
                    append-icon="mdi-play"
                    outlined
                    class="ml-2"
                  >
                    Play
                  </v-btn>
                </router-link>
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <v-form readonly>
                  <v-text-field
                    label="Shareable code"
                    :value="customization.shareable_code"
                    persistent-placeholder
                  >
                  </v-text-field>
                  <v-textarea
                    v-for="(value, name, index) in customization.data
                      .custom_text"
                    :key="index"
                    :label="label_map[customization.game_id][name]"
                    :value="value"
                    :rows="calculateRows(value)"
                    persistent-placeholder
                    auto-grow
                  ></v-textarea>
                </v-form>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import { Auth } from "aws-amplify";

async function getJwtToken() {
  try {
    const session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  } catch (error) {
    console.error("Error getting JWT token:", error);
    return null;
  }
}

export default {
  data() {
    return {
      error: false,
      label_map: {
        "game-0001": {
          welcome_message: "Welcome message",
          game_over_message: "Game over message",
          game_completed_message: "Game completed message",
          points: "Points to win",
        },
        "game-0002": {
          welcome_message: "Welcome message",
          game_over_message: "Game over message",
          game_completed_message: "Game completed message",
          bonus_icon: "Items to collect for a bonus",
          points: "Points to win",
        },
        "game-0003": {
          welcome_message: "Welcome message",
          game_over_message: "Game over message",
          game_completed_message: "Game completed message",
          points: "Points to win",
        },
      },
      title_map: {
        "game-0001": "Custom Keeper",
        "game-0002": "Flappy Friends",
        "game-0003": "Self Slider",
      },
      game_page_map: {
        "game-0001": "CustomKeeperGame",
        "game-0002": "FlappyFriendsGame",
        "game-0003": "SelfSliderGame",
      },
    };
  },
  computed: {
    ...mapGetters(["getCustomizations", "getLastFetch"]),
  },
  mounted() {
    // Check if the data is stale (older than 5 minutes) or doesn't exist
    const currentTime = new Date().getTime();
    if (
      !this.getCustomizations ||
      !this.getLastFetch ||
      currentTime - this.getLastFetch > 5 * 60 * 1000
    ) {
      this.fetchCustomizations();
    }
    window.addEventListener("beforeunload", this.clearCustomizations);
  },
  beforeUnmount() {
    window.removeEventListener("beforeunload", this.clearCustomizations);
  },
  inject: ["user"],
  methods: {
    ...mapActions(["setCustomizations", "clearCustomizations"]),
    calculateRows(text) {
      if (!text || typeof text !== "string") return 1;
      const lines = text.split(/\r\n|\r|\n/).length;
      return Math.max(lines, 1);
    },
    async fetchCustomizations() {
      const token = await getJwtToken();
      const user_id = this.user().attributes.sub;

      try {
        const response = await axios.get(
          process.env.VUE_APP_API_URL + user_id + "/customizations",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
        this.setCustomizations(
          response.data.map((customization) => {
            return {
              game_id: customization.game_id,
              customization_id: customization.customization_id,
              shareable_code: customization.shareable_code,
              metadata: customization.customization_data.metadata ?? {
                gift_name: customization.customization_id,
              },
              data: customization.customization_data,
            };
          })
        );
      } catch (error) {
        this.error = true;
      }
    },
  },
};
</script>

<style scoped>
.expanders-container {
  padding-top: 16px;
  width: 75%;
  margin: 0 auto;
}
</style>
