const state = {
  customizations: null,
  lastFetch: null,
};

const getters = {
  getCustomizations: (state) => state.customizations,
  getLastFetch: (state) => state.lastFetch,
};

const actions = {
  setCustomizations: ({ commit }, customizations) => {
    commit("updateCustomizations", customizations);
    commit("updateLastFetch", new Date().getTime());
  },
  clearCustomizations: ({ commit }) => {
    commit("updateCustomizations", null);
    commit("updateLastFetch", null);
  },
};

const mutations = {
  updateCustomizations: (state, customizations) => {
    state.customizations = customizations;
  },
  updateLastFetch: (state, timestamp) => {
    state.lastFetch = timestamp;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
