<template>
  <div>
    <game-form
      :game-id="'game-0003'"
      :form-data="form"
      :cover-image="require('@/assets/ss_cover.png')"
      :files="[
        'image1',
        'image2',
        'image4',
        'image8',
        'image16',
        'image32',
        'image64',
        'image128',
        'image256',
        'image512',
        'image1024',
        'image2048',
        'image4096',
        'image8192',
      ]"
      :num-tabs="4"
      @formatData="formatData"
    >
      <v-stepper-header>
        <v-stepper-item value="1"> Basics </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="2"> Image upload </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="3"> Custom messages </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="4"> Game settings </v-stepper-item>
      </v-stepper-header>

      <v-stepper-window>
        <v-stepper-window-item value="1">
          <basic-text-field
            label="Gift name"
            placeholder="Game for my friend"
            hint="The name that
                you can use to track your customized game. Not visible within the game."
            v-model="form.gift_name"
          >
          </basic-text-field>
          <basic-text-field
            label="Name of the recipient"
            placeholder="My friend Bob"
            hint="Who's the gift for?"
            v-model="form.recipient_name"
          >
          </basic-text-field>
        </v-stepper-window-item>
        <v-stepper-window-item value="2">
          <v-alert type="info" density="compact"
            >Images should be square for the best results</v-alert
          ><br />
          <div v-for="(input, index) in fileInputs" :key="index">
            <basic-file-input
              :label="input.label"
              accept="image/png"
              @file-changed="onFileChanged"
              :imgRef="input.imgRef"
              :cropperAspectRatio="1.0"
            >
            </basic-file-input>
          </div>
        </v-stepper-window-item>

        <v-stepper-window-item value="3">
          <basic-text-area
            label="Welcome Message"
            placeholder="Hi there, have fun playing this game!"
            hint="This message will display when first starting the game"
            v-model="form.welcome_message"
          >
          </basic-text-area>
          <basic-text-area
            label="Game Over Message"
            placeholder="You lose!"
            hint="This message will display when the player loses a game"
            v-model="form.game_over_message"
          ></basic-text-area>
          <basic-text-area
            label="Game Completed Message"
            placeholder="You win!"
            hint="This message will display when the player wins a game"
            :rows="6"
            v-model="form.game_completed_message"
          ></basic-text-area>
        </v-stepper-window-item>

        <v-stepper-window-item value="4">
          <div class="text-caption" style="margin-top: 16px">
            Points needed to win
          </div>
          <v-slider
            v-model="form.points"
            thumb-label="always"
            step="256"
            min="256"
            max="4096"
            style="margin-left: 48px; margin-right: 48px"
          ></v-slider>
        </v-stepper-window-item>
      </v-stepper-window>
    </game-form>
  </div>
</template>

<script>
import GameForm from "@/components/GameForm.vue";
import BasicTextField from "@/components/BasicTextField.vue";
import BasicTextArea from "@/components/BasicTextArea.vue";
import BasicFileInput from "@/components/BasicFileInput.vue";

export default {
  components: {
    GameForm,
    BasicTextField,
    BasicTextArea,
    BasicFileInput,
  },
  data() {
    return {
      form: {
        gift_name: "",
        recipient_name: "",
        welcome_message: "",
        game_over_message: "",
        game_completed_message: "",
        points: 256,
        image1: null,
        image2: null,
        image4: null,
        image8: null,
        image16: null,
        image32: null,
        image64: null,
        image128: null,
        image256: null,
        image512: null,
        image1024: null,
        image2048: null,
        image4096: null,
        image8192: null,
      },
      fileInputs: [
        { label: "Image 1", imgRef: "image1" },
        { label: "Image 2", imgRef: "image2" },
        { label: "Image 4", imgRef: "image4" },
        { label: "Image 8", imgRef: "image8" },
        { label: "Image 16", imgRef: "image16" },
        { label: "Image 32", imgRef: "image32" },
        { label: "Image 64", imgRef: "image64" },
        { label: "Image 128", imgRef: "image128" },
        { label: "Image 256", imgRef: "image256" },
        { label: "Image 512", imgRef: "image512" },
        { label: "Image 1024", imgRef: "image1024" },
        { label: "Image 2048", imgRef: "image2048" },
        { label: "Image 4096", imgRef: "image4096" },
        { label: "Image 8192", imgRef: "image8192" },
      ],
      images: {}, // Holds the data URL for displaying the image preview
      fileBlobs: {},
    };
  },
  methods: {
    onFileChanged(payload) {
      this.images[payload.ref] = payload.imageSrc;
      this.fileBlobs[payload.ref] = payload.fileBlob;
      if (payload != null) {
        this.form[payload.ref] = payload.fileBlob;
      } else {
        this.form[payload.ref] = null;
      }
    },
    formatData(customizationData) {
      customizationData.metadata = {
        gift_name: this.form.gift_name,
        recipient_name: this.form.recipient_name,
      };
      customizationData.custom_text = {
        welcome_message: this.form.welcome_message,
        game_over_message: this.form.game_over_message,
        game_completed_message: this.form.game_completed_message,
        points: this.form.points,
      };
      customizationData.custom_images = {
        image1: null,
        image2: null,
        image4: null,
        image8: null,
        image16: null,
        image32: null,
        image64: null,
        image128: null,
        image256: null,
        image512: null,
        image1024: null,
        image2048: null,
        image4096: null,
        image8192: null,
      };
    },
  },
};
</script>
