<template>
  <v-item-group
    v-model="iconSelection"
    selected-class="bg-success"
    mandatory
    @update:modelValue="onIconChanged"
    ><v-row justify="start">
      <v-col v-for="icon in this.icons" :key="icon">
        <v-item v-slot="{ toggle, selectedClass }">
          <v-card
            :class="['ma-2', selectedClass]"
            height="64"
            width="64"
            @click="toggle"
          >
            <v-img :src="this.getIconPath(icon)"></v-img
          ></v-card>
        </v-item>
      </v-col>
    </v-row>
  </v-item-group>
</template>

<script>
export default {
  props: {
    icons: {
      type: Array,
      require: true,
    },
  },
  data() {
    return {
      iconSelection: 0,
    };
  },
  methods: {
    getIconPath(icon_id) {
      return require("../assets/icons/emoji_" + icon_id + ".png");
    },
    onIconChanged() {
      var icon_id = this.icons[this.iconSelection];
      this.$emit("iconChanged", icon_id);
    },
  },
};
</script>
