<template>
  <game-play
    url="gamebuilds/self-slider/SelfSlider.html"
    max-width="1220"
    max-height="650"
  >
  </game-play>
</template>

<script>
import GamePlay from "@/components/GamePlay.vue";

export default {
  components: {
    GamePlay,
  },
};
</script>
