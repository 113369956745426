<template>
  <div>
    <game-form
      :game-id="'game-0001'"
      :form-data="form"
      :cover-image="require('@/assets/ck_cover.png')"
      :files="['face_happy']"
      @formatData="formatData"
    >
      <v-stepper-header>
        <v-stepper-item value="1"> Basics </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="2"> Image upload </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="3"> Custom messages </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="4"> Game settings </v-stepper-item>
      </v-stepper-header>

      <v-stepper-window>
        <v-stepper-window-item value="1">
          <basic-text-field
            label="Gift name"
            placeholder="Game for my friend"
            hint="The name that
              you can use to track your customized game. Not visible within the game."
            v-model="form.gift_name"
          >
          </basic-text-field>
          <basic-text-field
            label="Name of the recipient"
            placeholder="My friend Bob"
            hint="Who's the gift for?"
            v-model="form.recipient_name"
          >
          </basic-text-field>
        </v-stepper-window-item>
        <v-stepper-window-item value="2">
          <basic-file-input
            label="Happy Face Image"
            accept="image/png"
            @file-changed="onFileChanged"
            imgRef="face_happy"
            :cropperInitialAspectRatio="0.9"
          >
          </basic-file-input>
          <image-preview-c-k
            :imageSrc="imageSrc"
            :fileBlob="fileBlob"
          ></image-preview-c-k>
        </v-stepper-window-item>

        <v-stepper-window-item value="3">
          <basic-text-area
            label="Welcome Message"
            placeholder="Hi there, have fun playing this game!"
            hint="This message will display when first starting the game"
            v-model="form.welcome_message"
          >
          </basic-text-area>
          <basic-text-area
            label="Game Over Message"
            placeholder="You lose!"
            hint="This message will display when the player loses a game"
            v-model="form.game_over_message"
          ></basic-text-area>
          <basic-text-area
            label="Game Completed Message"
            placeholder="You win!"
            hint="This message will display when the player wins a game"
            :rows="6"
            v-model="form.game_completed_message"
          ></basic-text-area>
        </v-stepper-window-item>

        <v-stepper-window-item value="4">
          <div class="text-caption" style="margin-top: 16px">
            Points needed to win
          </div>
          <v-slider
            v-model="form.points"
            thumb-label="always"
            step="1"
            min="5"
            max="25"
          ></v-slider>
        </v-stepper-window-item>
      </v-stepper-window>
    </game-form>
  </div>
</template>

<script>
import GameForm from "@/components/GameForm.vue";
import BasicTextField from "@/components/BasicTextField.vue";
import BasicTextArea from "@/components/BasicTextArea.vue";
import BasicFileInput from "@/components/BasicFileInput.vue";
import ImagePreviewCK from "@/components/ImagePreviewCK.vue";

export default {
  components: {
    GameForm,
    ImagePreviewCK,
    BasicTextField,
    BasicTextArea,
    BasicFileInput,
  },
  data() {
    return {
      form: {
        gift_name: "",
        recipient_name: "",
        welcome_message: "",
        game_over_message: "",
        game_completed_message: "",
        points: 5,
        face_happy: null,
      },
      imageSrc: null, // Holds the data URL for displaying the image preview
      fileBlob: null,
    };
  },
  methods: {
    onFileChanged(payload) {
      this.imageSrc = payload.imageSrc;
      this.fileBlob = payload.fileBlob;
      if (payload != null) {
        this.form[payload.ref] = payload.fileBlob;
      } else {
        this.form[payload.ref] = null;
      }
    },
    formatData(customizationData) {
      customizationData.metadata = {
        gift_name: this.form.gift_name,
        recipient_name: this.form.recipient_name,
      };
      customizationData.custom_text = {
        welcome_message: this.form.welcome_message,
        game_over_message: this.form.game_over_message,
        game_completed_message: this.form.game_completed_message,
        points: this.form.points,
      };
      customizationData.custom_images = {
        face_happy: null,
      };
    },
  },
};
</script>
