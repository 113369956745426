<template>
  <v-container fluid class="mx-auto">
    <v-row justify="center">
      <v-col cols="12" md="8">
        <v-card elevation="2" height="550" class="d-flex flex-column">
          <v-carousel
            height="400"
            show-arrows="hover"
            delimiter-icon="mdi-square"
            style="background-color: rgb(0, 150, 136)"
            hide-delimiter-background
            cycle
          >
            <v-carousel-item
              src="../assets/ck_screenshot.png"
            ></v-carousel-item>
            <v-carousel-item
              src="../assets/ff_screenshot.png"
            ></v-carousel-item>
            <v-carousel-item
              src="../assets/ss_screenshot.png"
            ></v-carousel-item>
          </v-carousel>
          <v-card-title> See yourself in a whole new world! </v-card-title>
          <v-spacer />
          <v-card-text>
            Discover a world where YOU are the star! Customize exciting games,
            gift a personalized experience, and share your unique adventures
            with just a URL. Play anywhere, anytime, on any device.
          </v-card-text>

          <v-card-actions class="justify-center">
            <v-btn
              large
              color="primary"
              variant="outlined"
              to="/games/custom-keeper/play"
            >
              Play a Demo
            </v-btn>

            <v-btn large color="secondary" variant="outlined" to="/catalog">
              Show Games Catalog
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "LandingPage",
};
</script>
