<template>
  <v-app>
    <nav-bar
      :user="user"
      :isLoading="isLoading"
      @user-status-changed="updateUser"
    ></nav-bar>
    <v-main>
      <router-view />
    </v-main>
    <footer-component></footer-component>
  </v-app>
</template>

<script>
import { Auth } from "aws-amplify";
import NavBar from "./components/NavBar.vue";
import FooterComponent from "./components/FooterComponent.vue";

export default {
  components: {
    NavBar,
    FooterComponent,
  },
  data() {
    return {
      user: null,
      isLoading: true,
    };
  },

  async mounted() {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      this.user = currentUser;
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  },

  methods: {
    updateUser(status) {
      if (!status) {
        this.user = null;
      } else {
        this.fetchUser();
      }
    },

    async fetchUser() {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        this.user = currentUser;
      } catch (error) {
        console.error(error);
      }
    },
  },
  provide() {
    return {
      user: () => this.user,
    };
  },
};
</script>

<style>
body {
  background-image: url("~@/assets/background.png");
  background-repeat: repeat;
  background-size: 256px;
  background-color: white;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #002344;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.v-application {
  background-color: transparent !important;
}

.v-main {
  flex: 1 0 auto; /* This allows the main content to grow and shrink as needed */
  background-color: rgba(255, 255, 255, 0.75);
}
</style>
