<template>
  <div>
    <game-form
      :game-id="'game-0002'"
      :form-data="form"
      :cover-image="require('@/assets/ff_cover.png')"
      :files="['face1', 'face2']"
      :num-tabs="5"
      @formatData="formatData"
    >
      <v-stepper-header>
        <v-stepper-item value="1"> Basics </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="2"> Image upload </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="3"> Icon selection </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="4"> Custom messages </v-stepper-item>
        <v-divider></v-divider>
        <v-stepper-item value="5"> Game settings </v-stepper-item>
      </v-stepper-header>

      <v-stepper-window>
        <v-stepper-window-item value="1">
          <basic-text-field
            label="Gift name"
            placeholder="Game for my friend"
            hint="The name that
                you can use to track your customized game. Not visible within the game."
            v-model="form.gift_name"
          >
          </basic-text-field>
          <basic-text-field
            label="Name of the recipient"
            placeholder="My friend Bob"
            hint="Who's the gift for?"
            v-model="form.recipient_name"
          >
          </basic-text-field>
        </v-stepper-window-item>
        <v-stepper-window-item value="2">
          <basic-file-input
            label="Face one"
            accept="image/png"
            @file-changed="onFileChanged"
            imgRef="face1"
            :cropperInitialAspectRatio="0.9"
          >
          </basic-file-input>
          <basic-file-input
            label="Face two"
            accept="image/png"
            @file-changed="onFileChanged"
            imgRef="face2"
            :cropperInitialAspectRatio="0.9"
          >
          </basic-file-input>
          <image-preview-f-f
            :images="images"
            :fileBlobs="fileBlobs"
          ></image-preview-f-f>
        </v-stepper-window-item>

        <v-stepper-window-item value="3">
          <div class="text-caption" style="margin-top: 16px">
            Item to collect for bonus points
          </div>
          <IconSelector
            :icons="icons"
            @iconChanged="onIconSelect"
          ></IconSelector>
        </v-stepper-window-item>

        <v-stepper-window-item value="4">
          <basic-text-area
            label="Welcome Message"
            placeholder="Hi there, have fun playing this game!"
            hint="This message will display when first starting the game"
            v-model="form.welcome_message"
          >
          </basic-text-area>
          <basic-text-area
            label="Game Over Message"
            placeholder="You lose!"
            hint="This message will display when the player loses a game"
            v-model="form.game_over_message"
          ></basic-text-area>
          <basic-text-area
            label="Game Completed Message"
            placeholder="You win!"
            hint="This message will display when the player wins a game"
            :rows="6"
            v-model="form.game_completed_message"
          ></basic-text-area>
        </v-stepper-window-item>

        <v-stepper-window-item value="5">
          <div class="text-caption" style="margin-top: 16px">
            Points needed to win
          </div>
          <v-slider
            v-model="form.points"
            thumb-label="always"
            step="30"
            min="30"
            max="300"
            style="margin-left: 48px; margin-right: 48px"
          ></v-slider>
        </v-stepper-window-item>
      </v-stepper-window>
    </game-form>
  </div>
</template>

<script>
import GameForm from "@/components/GameForm.vue";
import BasicTextField from "@/components/BasicTextField.vue";
import BasicTextArea from "@/components/BasicTextArea.vue";
import BasicFileInput from "@/components/BasicFileInput.vue";
import IconSelector from "@/components/IconSelector.vue";
import ImagePreviewFF from "@/components/ImagePreviewFF.vue";

export default {
  components: {
    GameForm,
    ImagePreviewFF,
    BasicTextField,
    BasicTextArea,
    BasicFileInput,
    IconSelector,
  },
  data() {
    return {
      form: {
        gift_name: "",
        recipient_name: "",
        welcome_message: "",
        game_over_message: "",
        game_completed_message: "",
        points: 30,
        face1: null,
        face2: null,
        bonus_icon: "",
      },
      icons: this.getIconFileNames(),
      images: { face1: null, face2: null }, // Holds the data URL for displaying the image preview
      fileBlobs: { face1: null, face2: null },
      selectedIcon: "u1f94e",
    };
  },
  methods: {
    onFileChanged(payload) {
      this.images[payload.ref] = payload.imageSrc;
      this.fileBlobs[payload.ref] = payload.fileBlob;
      if (payload != null) {
        this.form[payload.ref] = payload.fileBlob;
      } else {
        this.form[payload.ref] = null;
      }
    },
    formatData(customizationData) {
      customizationData.metadata = {
        gift_name: this.form.gift_name,
        recipient_name: this.form.recipient_name,
      };
      customizationData.custom_text = {
        welcome_message: this.form.welcome_message,
        game_over_message: this.form.game_over_message,
        game_completed_message: this.form.game_completed_message,
        points: this.form.points,
        bonus_icon: this.selectedIcon,
      };
      customizationData.custom_images = {
        face1: null,
        face2: null,
      };
    },
    getIconFileNames() {
      const context = require.context("@/assets/icons", false, /\.png$/);
      const idPattern = /emoji_([^.]+)\.png/; // Regular expression to capture the id part

      var imageNames = context
        .keys()
        .map((key) => {
          const match = key.match(idPattern);
          return match ? match[1] : null; // Extracts the id part if the pattern matches
        })
        .filter((id) => id !== null); // Filter out any nulls (in case of non-matching filenames)

      return imageNames;
    },
    onIconSelect(icon_id) {
      this.selectedIcon = icon_id;
    },
  },
};
</script>
