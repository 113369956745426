<template>
  <div class="input-cropper-container">
    <div class="file-input-preview">
      <v-file-input
        :rules="rules"
        :label="label"
        :accept="accept"
        v-model="selectedFile"
        @change="onFileChange"
        @click:clear="onFileClear"
        prepend-icon="mdi-camera"
        :show-size="true"
        :clearable="true"
      ></v-file-input>
      <div v-if="croppedImageSrc" class="image-preview">
        <v-btn
          v-if="!showCropper"
          @click="showCropper = true"
          prepend-icon="mdi-crop"
          variant="outlined"
          stacked
          style="width: 75px"
          >Show</v-btn
        >
        <v-btn
          v-if="showCropper"
          @click="showCropper = false"
          prepend-icon="mdi-crop"
          variant="outlined"
          stacked
          style="width: 75px"
          >Hide</v-btn
        >
      </div>
    </div>
    <vue-cropper
      v-show="showCropper"
      class="cropper-container"
      :ref="'cropper'"
      :src="imageSrc"
      :viewMode="2"
      :aspectRatio="cropperAspectRatio"
      :initialAspectRatio="cropperInitialAspectRatio"
      :autoCropArea="1.0"
      :zoomable="false"
      @crop="cropImage"
    ></vue-cropper>
  </div>
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  components: { VueCropper },
  emits: ["file-changed"],
  props: {
    rules: {
      type: Array,
      default: () => [
        (value) => {
          if (value && value.length) return true;
          return "No file uploaded";
        },
        (value) => {
          if (value[0].size < 10000000) return true;
          return "File size should be less than 10 MB!";
        },
      ],
    },
    imgRef: { type: String, required: true },
    label: String,
    accept: String,
    cropperAspectRatio: {
      type: Number,
      default: undefined,
    },
    cropperInitialAspectRatio: {
      type: Number,
      default: undefined,
    },
  },
  data() {
    return {
      selectedFile: null,
      fileBlob: null,
      imageSrc: null,
      croppedImageSrc: null,
      showCropper: false,
    };
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    onFileChange(e) {
      if (!this.selectedFile || this.selectedFile.length === 0) {
        this.onFileClear();
      }
      this.showCropper = true;
      this.fileBlob = this.selectedFile[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        this.imageSrc = e.target.result;
        // this.croppedImageSrc = e.target.result;
        // this.cropImage();
        this.$refs.cropper.replace(this.imageSrc);
        this.$emit("file-changed", {
          ref: this.imgRef,
          fileBlob: this.fileBlob,
          imageSrc: this.imageSrc,
        });
      };
      reader.readAsDataURL(this.fileBlob);
    },
    onFileClear() {
      this.showCropper = false;
      this.imageSrc = null;
      this.croppedImageSrc = null;
      this.$emit("file-changed", {
        ref: this.imgRef,
        fileBlob: null,
        imageSrc: null,
      });
    },
    cropImage() {
      var canvas;
      try {
        const cropper = this.$refs.cropper.cropper;
        canvas = cropper.getCroppedCanvas();
      } catch {
        return;
      }

      if ((canvas.width == 0) | (canvas.height == 0)) {
        // No pixels selected
        return;
      }

      // Convert the canvas to a Blob (which can be used like a File)
      canvas.toBlob((blob) => {
        this.fileBlob = blob;

        // Convert the blob to a data URL for the imageSrc
        const reader = new FileReader();
        reader.onload = (e) => {
          this.croppedImageSrc = e.target.result;
          // Emit the file-changed event with the cropped image data
          this.$emit("file-changed", {
            ref: this.imgRef,
            fileBlob: this.fileBlob,
            imageSrc: this.croppedImageSrc,
          });
        };
        reader.readAsDataURL(blob);
      });
    },
  },
};
</script>

<style>
.input-cropper-container {
  display: flex;
  flex-direction: column;
}

.file-input-preview {
  display: flex;
  align-items: center;
  justify-content: center; /* Adjust alignment as needed */
  height: 100px;
}

.v-file-input {
  flex-grow: 1; /* File input takes up remaining space */
  justify-content: center;
}

.image-preview {
  display: flex;
  margin-left: 16px; /* Spacing between file input and image */
  justify-content: space-between;
}

.cropper-container {
  width: 100%;
  max-height: 450px;
  margin-bottom: 16px;
}
</style>
