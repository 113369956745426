<template>
  <v-textarea
    :rules="rules"
    :label="label"
    :placeholder="placeholder"
    :rows="rows"
    :hint="hint"
    :clearable="clearable"
    :persistent-clear="persistentClear"
    :auto-grow="autoGrow"
    :required="required"
    :value="computedModelValue"
    @input="updateValue"
    ref="textField"
    @click:clear="clearTextField"
  >
  </v-textarea>
</template>

<script>
export default {
  props: {
    rules: {
      type: Array,
      default: () => [(value) => !!value || "Required."],
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    hint: {
      type: String,
      default: "",
    },
    rows: {
      type: Number,
      default: 3,
    },
    modelValue: {
      type: String,
      required: true,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    persistentClear: {
      type: Boolean,
      default: true,
    },
    autoGrow: {
      type: Boolean,
      default: true,
    },
    required: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    computedModelValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    clearTextField() {
      this.$refs.textField.value = ""; // Clear the text field value
      this.computedModelValue = ""; // Update the computedModelValue to notify the parent
    },
    updateValue(event) {
      this.computedModelValue = event.target.value;
    },
  },
  emits: ["update:modelValue"],
};
</script>
