<template>
  <v-card>
    <v-alert
      v-if="(images.face1 == null) & (images.face2 == null)"
      type="info"
      text="You've not uploaded an image yet"
      density="compact"
    ></v-alert>
    <v-alert
      v-if="((images.face1 != null) | (images.face2 != null)) & !isValid"
      type="error"
      text="The image you've uploaded is not a valid image."
      density="compact"
    ></v-alert>
    <v-alert
      v-if="(images.face1 == null) & (images.face2 != null)"
      type="error"
      text="Don't forget to upload a 'face 1' as well!"
      density="compact"
    ></v-alert>
    <v-alert
      v-if="
        ((images.face1 != null) | (images.face2 != null)) &
        !isTransparent &
        isValid
      "
      type="warning"
      density="compact"
    >
      It looks like the image that you've uploaded is not transparant. For the
      nicest results in your game, make sure that image you select has a
      transparant background. You can use a tool like
      <a href="https://removal.ai" target="_blank">this</a> to do so.
    </v-alert>
    <v-alert
      v-if="(images.face1 != null) & isTransparent & isValid"
      type="success"
      text="The image you've selected seems to pass our checks. If you like how it looks, you can continue the process!"
      density="compact"
    ></v-alert>
    <v-img :src="require('@/assets/ff-preview-background.png')" height="175">
      <v-img
        v-if="images.face1 != null"
        :src="images.face1"
        style="margin-top: 40px; margin-left: 26px"
        height="80"
        ><v-img
          v-if="images.face2 != null"
          :src="images.face2"
          style="margin-right: 120px"
          height="80"
        ></v-img
      ></v-img>
    </v-img>
  </v-card>
</template>

<script>
export default {
  props: {
    images: {
      type: Object,
      require: true,
    },
    fileBlobs: {
      type: Object,
      require: true,
    },
  },
  data: function () {
    return {
      isTransparent: false,
      isValid: false,
    };
  },
  methods: {
    checkCornerTransparency(fileBlob) {
      if (fileBlob == null) {
        return;
      }
      this.isValid = true;

      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        canvas.width = img.width;
        canvas.height = img.height;

        ctx.drawImage(img, 0, 0);

        const corners = [
          { x: 0, y: 0 }, // Top-left
          { x: img.width - 1, y: 0 }, // Top-right
          { x: 0, y: img.height - 1 }, // Bottom-left
          { x: img.width - 1, y: img.height - 1 }, // Bottom-right
        ];

        for (const corner of corners) {
          const pixelData = ctx.getImageData(corner.x, corner.y, 1, 1).data;
          if (pixelData[3] < 255) {
            this.isTransparent = true;
            URL.revokeObjectURL(img.src);
            return;
          }
        }

        this.isTransparent = false;
        URL.revokeObjectURL(img.src);
      };

      img.onerror = () => {
        this.isValid = false;
        URL.revokeObjectURL(img.src);
      };

      img.src = URL.createObjectURL(fileBlob);
    },
  },
  watch: {
    "fileBlobs.face1": function () {
      this.checkCornerTransparency(this.fileBlobs.face1);
    },
    // eslint-disable-next-line no-unused-vars
    "fileBlobs.face2": function () {
      this.checkCornerTransparency(this.fileBlobs.face2);
    },
  },
};
</script>
