<template>
  <div class="game-info-page">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" sm="10" md="10">
          <v-alert
            v-if="getGameTitle() == ''"
            title="Game id not found"
            type="error"
            class="text-left"
          >
            The game you are looking for can't be found. Please check the URL
            and try again.
          </v-alert>
          <v-card v-if="getGameTitle() != ''">
            <v-img :src="getGameImage()" height="200px" cover></v-img>
            <v-card-title>{{ getGameTitle() }}</v-card-title>
            <v-card-text
              >There's several ways to play your customized game. Choose your
              favorite platform below and follow the instructions to get
              started!</v-card-text
            >
            <v-row justify="center">
              <v-col cols="12" sm="12" md="12">
                <v-card elevation="4">
                  <v-tabs v-model="tab" bg-color="secondary">
                    <v-tab value="web">Web</v-tab>
                    <v-tab value="desktop">Desktop</v-tab>
                    <v-tab value="mobile">Mobile</v-tab>
                  </v-tabs>
                  <v-window v-model="tab">
                    <v-window-item value="web">
                      <v-card>
                        <v-card-text>
                          <v-btn
                            color="primary"
                            :to="getShareableUrl(shareableCode)"
                            >Click here to play</v-btn
                          >
                          <v-spacer style="margin-bottom: 16px"></v-spacer>
                          <strong>OR</strong>
                          <v-spacer style="margin-top: 16px"></v-spacer>
                          You can navigate to the following link: <br />
                          <a :href="getGameUrlWithBase(shareableCode)">{{
                            getGameUrlWithBase(shareableCode)
                          }}</a>
                          <br />
                          <br />
                          Then enter your code: <br />
                          <strong>{{ shareableCode }}</strong>
                        </v-card-text>
                      </v-card>
                    </v-window-item>

                    <v-window-item value="desktop">
                      <v-card>
                        <v-card-text>
                          First, download the game for your operating system:
                          <br />
                          <v-btn
                            color="primary"
                            @click="
                              downloadFile(
                                this.games[this.gameId]['windowsDownload']
                              )
                            "
                            prepend-icon="mdi-microsoft-windows"
                          >
                            Windows
                          </v-btn>
                          <!-- <v-btn
                            class="ml-4"
                            color="primary"
                            @click="
                              downloadFile(
                                this.games[this.gameId]['windowsDownload']
                              )
                            "
                            prepend-icon="mdi-linux"
                          >
                            Linux
                          </v-btn> -->
                          <v-spacer style="margin-bottom: 16px"></v-spacer>
                          Run the game once it's downloaded. Then enter your
                          code: <br />
                          <strong>{{ shareableCode }}</strong>
                        </v-card-text>
                      </v-card>
                    </v-window-item>

                    <v-window-item value="mobile">
                      <v-card>
                        <v-card-title>Coming soon!</v-card-title>
                        <v-card-text>
                          We are currently working on mobile applications for
                          our games. Please check back later! For now, you can
                          still play on the browser of your phone.
                        </v-card-text>
                        <v-btn
                          color="primary"
                          style="margin-bottom: 16px"
                          :to="getShareableUrl(shareableCode)"
                          >Click here to play
                        </v-btn>
                      </v-card>
                    </v-window-item>
                  </v-window>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "GameInfoPage",
  created() {
    this.gameId = this.$route.query["game-id"];
    this.shareableCode = this.$route.query["shareablecode"];
  },
  data() {
    return {
      tab: "web",
      gameId: null,
      shareableCode: null,
      publicPath: process.env.BASE_URL,
      games: {
        "game-0001": {
          title: "CustomKeeper",
          url: "/games/custom-keeper/play",
          image: require("@/assets/ck_cover.png"),
          windowsDownload: "CustomKeeper.exe",
        },
        "game-0002": {
          title: "FlappyFriends",
          url: "/games/flappy-friends/play",
          image: require("@/assets/ff_cover.png"),
          windowsDownload: "FlappyFriends.exe",
        },
        "game-0003": {
          title: "SelfSlider",
          url: "/games/self-slider/play",
          image: require("@/assets/ss_cover.png"),
          windowsDownload: "SelfSlider.exe",
        },
      },
    };
  },
  methods: {
    getGameTitle() {
      if (this.gameId in this.games) {
        return this.games[this.gameId]["title"];
      }
      return "";
    },
    getGameImage() {
      return this.games[this.gameId]["image"];
    },
    getGameUrl() {
      return this.games[this.gameId]["url"];
    },
    getGameUrlWithBase() {
      return window.location.origin + this.getGameUrl();
    },
    getShareableUrl(shareableCode) {
      var baseUrl = this.getGameUrl();
      baseUrl += "?shareablecode=" + shareableCode;
      return baseUrl;
    },
    downloadFile(fileName) {
      const fileUrl = `${this.publicPath}distributions/${fileName}`;
      window.location.href = fileUrl;
    },
  },
};
</script>
