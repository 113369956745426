import { createRouter, createWebHistory } from "vue-router";
import { Auth } from "aws-amplify";
import HomePage from "../views/HomePage.vue";
import HowToPage from "../views/HowToPage.vue";
import GamesCatalogPage from "../views/GamesCatalogPage.vue";
import GameInfoPage from "../views/GameInfoPage.vue";
import MyGames from "../views/MyGamesPage.vue";
import CustomKeeperForm from "../views/CustomKeeperForm.vue";
import FlappyFriendsForm from "../views/FlappyFriendsForm.vue";
import SelfSliderForm from "../views/SelfSliderForm.vue";
import CustomKeeperGame from "../views/CKGame.vue";
import FlappyFriendsGame from "../views/FFGame.vue";
import SelfSliderGame from "../views/SSGame.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
  },
  {
    path: "/howto",
    name: "HowTo",
    component: HowToPage,
  },
  {
    path: "/catalog",
    name: "GamesCatalog",
    component: GamesCatalogPage,
  },
  {
    path: "/games/custom-keeper",
    name: "CustomKeeper",
    component: CustomKeeperForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/game-info/",
    name: "GameInfo",
    component: GameInfoPage,
    meta: { requiresAuth: true },
  },
  {
    path: "/games/custom-keeper/play",
    name: "CustomKeeperGame",
    component: CustomKeeperGame,
  },
  {
    path: "/games/flappy-friends",
    name: "FlappyFriends",
    component: FlappyFriendsForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/games/flappy-friends/play",
    name: "FlappyFriendsGame",
    component: FlappyFriendsGame,
  },
  {
    path: "/games/self-slider",
    name: "SelfSlider",
    component: SelfSliderForm,
    meta: { requiresAuth: true },
  },
  {
    path: "/games/self-slider/play",
    name: "SelfSliderGame",
    component: SelfSliderGame,
  },
  {
    path: "/user/games",
    name: "My Games",
    component: MyGames,
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth) {
    try {
      await Auth.currentAuthenticatedUser();
      next();
    } catch (error) {
      next({ name: "Home" });
    }
  } else {
    next();
  }
});

export default router;
